import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import { COLOR_ACCENT, COLOR_BG_BLACK_2 } from 'shared';
import { socials } from 'widgets/layout/constants';

export const Community = () => {
  return (
    <Box mb={{ xs: 3, md: 12 }}>
      <Typography component="span" variant="h1">
        Meet the worldwide community
      </Typography>
      <Grid container justifyContent="end">
        <Grid item xs={12} md={8} mt={{ xs: 3, md: 6 }}>
          <Typography variant="h5" fontSize={{ xs: 16, md: 24 }} maxWidth={600}>
            Join a fast-growing community of developers and innovators connected all over the world, building the new
            era of the internet.
          </Typography>
        </Grid>
        <Grid item container xs={12} md={8} spacing={2} mt={{ xs: 4, md: 6 }}>
          {socials.map(({ Icon, url, title, description }) => {
            return (
              <Grid item xs={12} lg={6} key={url}>
                <Card
                  sx={{
                    minHeight: '120px',
                    padding: '24px 20px 18px 24px',
                  }}
                >
                  <Stack direction="row" spacing={2}>
                    <Stack
                      component="a"
                      target="_blank"
                      rel="noreferrer"
                      href={url}
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        flexShrink: 0,
                        width: 48,
                        height: 48,
                        borderRadius: '50%',
                        backgroundColor: COLOR_ACCENT,
                      }}
                    >
                      <Icon
                        sx={{
                          color: COLOR_BG_BLACK_2,
                        }}
                      />
                    </Stack>
                    <Box
                      sx={{
                        flexGrow: 1,
                      }}
                    >
                      <Typography mb={0.5} variant="h5" fontWeight={600}>
                        {title}
                      </Typography>
                      <Typography variant="body2" className="secondary font-secondary">
                        {description}
                      </Typography>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};
