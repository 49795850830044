import React from 'react';
import { Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, ModalSidebar } from 'shared';

export interface DecentralizedCommunityModalPayload {
  data?: null;
  type: ModalType.DecentralizedCommunity;
  isOpen?: boolean;
}

export const DecentralizedCommunityModal = React.forwardRef<
  HTMLElement,
  Pick<DecentralizedCommunityModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar
      isOpen={isOpen}
      ref={ref}
      onClose={onClose}
      title="Decentralized Autonomous Community (DAC)"
      icon={<Icons.People />}
      titleSx={{
        maxWidth: 338,
      }}
    >
      <Typography variant="inherit" color="inherit" component="p" fontFamily="inherit">
        Manufacturers listed on CratD2C Platforms connect through a{' '}
        <Typography
          fontFamily="inherit"
          variant="inherit"
          color="inherit"
          component="a"
          target="_blank"
          rel="noreferrer"
          href="https://www.investopedia.com/tech/what-dao/"
          sx={{
            textDecoration: 'underline',
          }}
        >
          Decentralized Autonomous
        </Typography>{' '}
        Community &quot;DAC&quot;, where the community VOTE FOR or VOTE AGAINST the Authenticity of such a company if it
        has the characteristics to be listed on the CratD2C Portal based on Service and Quality of Product Delivery.
      </Typography>
      <br />
      <Typography component="p" variant="inherit" color="inherit" fontFamily="inherit">
        Manufacturer with a favourable rating of DAC Votes after many transactions on the CratD2C Trading Portal
        receives a % of reflect commissions of overall transactions on the blockchain each quarter by vesting
        allocation.
      </Typography>
    </ModalSidebar>
  );
});
