import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { COLOR_STROKE } from '../colors';
import { FontFamilies } from '../Typography';

import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/montserrat/500.css';

export const getMuiCssBaselineOverrides = (theme: Theme): ComponentsOverrides['MuiCssBaseline'] => ({
  '*': {
    scrollBehavior: 'smooth',
  },
  '*::-webkit-scrollbar': {
    width: 6,
    height: '100%',
  },
  '*::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: COLOR_STROKE,
    borderRadius: 20,
  },
  html: {
    WebkitFontSmoothing: 'antialiased', // Antialiasing.
    MozOsxFontSmoothing: 'grayscale', // Antialiasing.
    boxSizing: 'border-box',
  },
  body: {
    overflowX: 'hidden',
    overflowY: 'overlay',
    margin: 0,
    background: theme.themeColors.background,
    fontFamily: FontFamilies.primary,
    minHeight: '100vh',

    '#root': {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },

    'span, div': {
      fontFeatureSettings: 'liga off',
    },
  },
  a: {
    textDecoration: 'none',
  },

  '@keyframes appear': {
    from: {
      transform: 'translateY(200px)',
      opacity: 0,
    },

    to: {
      transform: 'translateY(0)',
      opacity: 1,
    },
  },
});

export const getMuiCssBaselineDefaultProps = (): ComponentsProps['MuiCssBaseline'] => ({});
