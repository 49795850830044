import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Discord: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 22 17"
    sx={{
      width: '22px',
      height: '17px',
      ...sx,
    }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.088 16.1874C16.088 16.1874 15.429 15.4003 14.8799 14.7048C17.2777 14.0276 18.1928 12.5267 18.1928 12.5267C17.4424 13.0209 16.7286 13.3687 16.088 13.6066C15.1728 13.991 14.2942 14.2472 13.434 14.3937C11.6769 14.7231 10.0662 14.6316 8.69344 14.3754C7.65015 14.174 6.75329 13.8812 6.00286 13.5883C5.58188 13.4236 5.1243 13.2223 4.66672 12.966C4.63927 12.9477 4.61181 12.934 4.58436 12.9203C4.5569 12.9065 4.52945 12.8928 4.50199 12.8745C4.46539 12.8562 4.44708 12.8379 4.42878 12.8196C4.09932 12.6366 3.91629 12.5084 3.91629 12.5084C3.91629 12.5084 4.79484 13.9727 7.11936 14.6682C6.57026 15.3637 5.89304 16.1874 5.89304 16.1874C1.84802 16.0593 0.310547 13.4053 0.310547 13.4053C0.310547 7.51164 2.94622 2.73449 2.94622 2.73449C5.58188 0.757743 8.08943 0.812652 8.08943 0.812652L8.27246 1.03229C4.97788 1.98406 3.45871 3.43002 3.45871 3.43002C3.45871 3.43002 3.86138 3.21038 4.5386 2.89922C6.49705 2.03897 8.05282 1.80103 8.69344 1.74612C8.70739 1.74379 8.72104 1.74147 8.73447 1.73918C8.8268 1.72346 8.90872 1.70951 9.00459 1.70951C10.1211 1.56309 11.384 1.52648 12.7018 1.67291C14.4407 1.87424 16.3076 2.38673 18.2111 3.43002C18.2111 3.43002 16.7652 2.05727 13.6536 1.1055L13.9099 0.812652C13.9099 0.812652 16.4174 0.757743 19.0531 2.73449C19.0531 2.73449 21.6887 7.51164 21.6887 13.4053C21.6887 13.4053 20.133 16.0593 16.088 16.1874ZM5.79274 8.94729C5.79274 7.83079 6.61638 6.91563 7.65967 6.91563C8.70296 6.91563 9.5449 7.83079 9.5266 8.94729C9.5266 10.0638 8.70296 10.9789 7.65967 10.9789C6.63469 10.9789 5.79274 10.0638 5.79274 8.94729ZM12.4734 8.94729C12.4734 7.83079 13.2971 6.91563 14.3404 6.91563C15.3836 6.91563 16.2073 7.83079 16.2073 8.94729C16.2073 10.0638 15.3836 10.9789 14.3404 10.9789C13.3154 10.9789 12.4734 10.0638 12.4734 8.94729Z"
      fill="currentColor"
    />
  </SvgIcon>
);
