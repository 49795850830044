import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CratToken: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 18 21"
    sx={{
      width: '18px',
      height: '21px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M15.534 14.7393C14.6562 16.0166 13.1921 16.8793 11.3404 16.8793C10.4749 16.8793 9.68708 16.7167 8.98915 16.4115L12.153 14.4738L11.3776 13.0273L7.47855 15.4161C7.10322 15.0611 6.77752 14.6464 6.50147 14.1786L10.4811 11.7399L9.70566 10.2934L5.84389 12.659C5.61123 11.876 5.48717 11.01 5.48717 10.0744C5.48717 6.2655 7.87867 3.31592 11.4613 3.31592C12.8509 3.31592 14.4856 3.8899 15.3882 5.22035L17.7301 3.23629C16.3901 1.3053 13.6822 0.419434 11.4613 0.419434C6.14476 0.419434 2.09685 4.14871 2.09685 10.0711C2.09685 11.6769 2.39773 13.1467 2.94675 14.434L0 16.239L0.775462 17.6855L3.72532 15.8806C4.0169 16.3252 4.34257 16.7399 4.70239 17.1182L1.67189 18.9729L2.44735 20.4194L6.00828 18.2363C7.47544 19.2549 9.28381 19.8256 11.3155 19.8256C14.2654 19.8256 16.4615 18.651 18 16.5906L15.534 14.7393Z"
      fill="#B4EB56"
    />
  </SvgIcon>
);
