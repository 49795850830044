import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Pos: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 36 35"
    sx={{
      width: '36px',
      height: '35px',
      fill: 'none',
      color: '#B4EB56',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M17.9988 21.7378V20.2862C18.0201 19.1248 17.5682 18.0048 16.7468 17.1834C15.9255 16.362 14.8054 15.9101 13.644 15.9314H9.28915C8.12778 15.9101 7.00768 16.362 6.18633 17.1834C5.36497 18.0048 4.91301 19.1248 4.93431 20.2862V21.7378"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.9023 11.6128C20.9023 13.2162 22.2022 14.516 23.8056 14.516C25.409 14.516 26.7088 13.2162 26.7088 11.6128"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.4512 7.25806H28.1608C29.7643 7.25806 31.0641 8.55788 31.0641 10.1613V17.4193C31.0641 19.0228 29.7643 20.3226 28.1608 20.3226H22.3544"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.0552 25.4033C25.1836 27.0417 23.8577 28.3937 22.2367 29.2971C20.0095 30.528 17.3801 30.8088 14.9432 30.076C12.5063 29.3431 10.4679 27.6585 9.28906 25.4033"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="11.4669"
      cy="7.98377"
      r="3.62903"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.709 29.0333L26.7091 25.4044L23.0801 25.4043"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
