import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const QuestionCircle: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 26 26"
    sx={{
      width: '26px',
      height: '26px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M12.9987 13.7114L14.3262 12.9738C14.9887 12.6058 15.3997 11.9075 15.3997 11.1496C15.3212 9.88929 14.2403 8.92852 12.9795 8.99833C11.8529 8.95156 10.8525 9.71311 10.5977 10.8115"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="12.9998"
      cy="13"
      r="9.00375"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1002 16.9017C13.1002 16.9569 13.0554 17.0016 13.0002 17.0016C12.9449 17.0016 12.9002 16.9568 12.9002 16.9016C12.9001 16.8464 12.9449 16.8016 13.0001 16.8015C13.0267 16.8015 13.0522 16.812 13.0709 16.8308C13.0897 16.8496 13.1003 16.8751 13.1002 16.9017"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
