/* eslint-disable no-nested-ternary */
import { useDispatch } from 'react-redux';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { modalModel } from 'entities/modal';
import {
  COLOR_BG,
  COLOR_STROKE,
  CratLogoAnimated,
  TRANSITION_300,
  useBreakpoints,
  useCasesData,
  VideoPreviewCard,
} from 'shared';

const LOGO_CIRCLE_DIRAMETRE_LG = 200;
const LOGO_CIRCLE_DIRAMETRE_MD = 90;
const LOGO_CIRCLE_DIRAMETRE_SM = 69;

export const UseCases = () => {
  const { isDownSmLayout, isDownMdLayout } = useBreakpoints();
  const dispatch = useDispatch();

  const openCratInfoModal = () => {
    dispatch(
      modalModel.actions.openModal({
        type: modalModel.ModalType.CratNew,
      }),
    );
  };

  const logoCircleWidth = isDownSmLayout
    ? LOGO_CIRCLE_DIRAMETRE_SM
    : isDownMdLayout
    ? LOGO_CIRCLE_DIRAMETRE_MD
    : LOGO_CIRCLE_DIRAMETRE_LG;

  return (
    <Box component="section" mt={{ xs: 6, sm: 12 }}>
      <Stack gap={{ xs: 3, md: 6 }}>
        <Typography variant="h4" className="secondary">
          Use cases
        </Typography>
        <Box position="relative">
          <Grid container rowSpacing={{ xs: 4, md: 5.5 }} columnSpacing={4}>
            {useCasesData.map(({ id, image, src, subtitle, info }) => (
              <Grid item key={id} xs={12} md={6} container rowGap={{ xs: 1, md: 2 }} justifyContent="center">
                <VideoPreviewCard subtitle={subtitle} image={image} videoSrc={src} info={info} />
                <Typography variant="h5" className="regular">
                  {subtitle}
                </Typography>
              </Grid>
            ))}
            <Box
              onClick={openCratInfoModal}
              sx={{
                display: { xs: 'none', md: 'flex' },
                position: 'absolute',
                top: '50%',
                left: '50%',
                translate: '-50% -60%',
                cursor: 'pointer',
                transition: TRANSITION_300,
                width: logoCircleWidth,
                height: logoCircleWidth,
                borderRadius: '50%',
                background: COLOR_BG,
                border: '1px solid',
                borderColor: COLOR_STROKE,
                alignItems: 'center',
                justifyContent: 'center',
                flexShrink: 0,
                '&:hover': {
                  transform: 'scale(1.35)',
                },
              }}
            >
              <Box
                component="img"
                src={CratLogoAnimated}
                sx={{
                  width: { md: 67, sm: 46, xs: 28 },
                }}
              />
            </Box>
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};
