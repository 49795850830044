import { Chains, ChainType, NativeCurrency } from '../types';

export const mapChainToChainId: Record<
  Chains,
  {
    [chainType in ChainType]: number;
  }
> = {
  [Chains.BSC]: {
    mainnet: 56,
    testnet: 97,
  },
};

export const mapChainToNativeCurrency: Record<
  Chains,
  {
    [chainType in ChainType]: NativeCurrency;
  }
> = {
  [Chains.BSC]: {
    mainnet: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    testnet: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
  },
};

export const mapChainToRpc: Record<
  Chains,
  {
    [chainType in ChainType]: string;
  }
> = {
  [Chains.BSC]: {
    mainnet: 'https://bsc-dataseed.binance.org/',
    testnet: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  },
};

export const mapChainToBlockExplorerUrl: Record<
  Chains,
  {
    [chainType in ChainType]: string;
  }
> = {
  [Chains.BSC]: {
    mainnet: 'https://bscscan.com',
    testnet: 'https://testnet.bscscan.com',
  },
};
