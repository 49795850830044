import React from 'react';
import { Box, Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, ModalSidebar } from 'shared';

import { list } from './constants';

export interface LuxuryModalPayload {
  data?: null;
  type: ModalType.Luxury;
  isOpen?: boolean;
}

export const LuxuryModal = React.forwardRef<
  HTMLElement,
  Pick<LuxuryModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar
      isOpen={isOpen}
      ref={ref}
      onClose={onClose}
      title="CRAT Luxury LifeStyle Booking Portal"
      icon={<Icons.Diamond />}
    >
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit" fontWeight={700}>
        CratD2C&#39;s decentralized Luxury Lifestyle Booking Portal is an online booking platform that leverages
        blockchain technology and decentralized systems to facilitate bookings and reservations for luxury experiences
        such as hotels, travel, cruise, restaurants, events, and other exclusive services. In addition, the booking
        platform co-houses some fundamental decentralized features, which make holidays and travel much more fun,
        cost-friendly, & rewarding.
      </Typography>
      <br />
      <Box component="ol">
        {list.map((item, i) => {
          return (
            <React.Fragment key={item.id}>
              <Typography
                component="li"
                variant="inherit"
                color="inherit"
                fontFamily="inherit"
                sx={{
                  position: 'relative',
                  paddingLeft: '24px',
                  '&:before': {
                    content: `"${i + 1}."`,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    fontSize: 'inherit',
                    fontFamily: 'inherit',
                  },
                }}
              >
                {item.text}
              </Typography>
              <br />
            </React.Fragment>
          );
        })}
      </Box>
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        With CratD2C&#39;s decentralized luxury lifestyle booking portal, users can be sure of a secure, transparent,
        and convenient way to book and manage luxury experiences while reducing reliance on centralized intermediaries
        and enhancing data privacy.
      </Typography>
    </ModalSidebar>
  );
});
