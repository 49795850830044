import React from 'react';
import { Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, ModalSidebar } from 'shared';

export interface PaymentGatewayModalPayload {
  data?: null;
  type: ModalType.PaymentGateway;
  isOpen?: boolean;
}

export const PaymentGatewayModal = React.forwardRef<
  HTMLElement,
  Pick<PaymentGatewayModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar
      isOpen={isOpen}
      ref={ref}
      onClose={onClose}
      title="CratD2C Universal Payment Gateway"
      icon={<Icons.Exchange />}
    >
      <Typography variant="inherit" color="inherit" fontFamily="inherit">
        In the current world where cryptocurrencies and web3 applications are gaining much adoption, quite a few
        individuals own or apply their usage for daily activities. However, the recent wave of eCommerce growth implies
        that cryptocurrency can be used for any means of payment wherever possible, so the relevant industries are worth
        paying close attention to it.
      </Typography>
      <br />
      <Typography variant="inherit" color="inherit" fontFamily="inherit">
        The main points where eCommerce businesses gain many transactions in the real world are PoS Terminals, Online
        Widget, API Transactions, and Vending Machines.
      </Typography>
      <br />
      <Typography variant="inherit" color="inherit" fontFamily="inherit">
        The CratD2C Universal Payment Gateway give immediate solutions on a global scale in this perspective for this
        paradigm shift.
      </Typography>
    </ModalSidebar>
  );
});
