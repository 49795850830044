import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Plus: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 14 14"
    sx={{
      width: '14px',
      height: '14px',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M7.0013 1.66675V12.3334M12.3346 7.00008L1.66797 7.00008"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
