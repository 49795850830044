import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Exchange: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 36 36"
    sx={{
      width: '36px',
      height: '36px',
      fill: 'none',
      color: '#B4EB56',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M7.79492 12.8645C10.3345 8.1975 15.7165 5.84806 20.8655 7.15869C26.0145 8.46932 29.6182 13.106 29.6174 18.4192"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9995 14.8555V14.0626"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9995 21.9844V22.7759"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9354 21.1349C16.2406 21.6582 16.7993 21.9817 17.4051 21.9859H18.7121C19.5533 21.9841 20.2617 21.3564 20.3646 20.5214C20.4675 19.6865 19.9329 18.9055 19.1172 18.6995L16.8895 18.1404C16.072 17.9369 15.5348 17.1554 15.6376 16.3193C15.7403 15.4831 16.4508 14.855 17.2932 14.8555H18.6002C19.2042 14.8594 19.7612 15.1818 20.0655 15.7036"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.38086 18.4192C6.38005 23.7324 9.98379 28.3691 15.1328 29.6797C20.2818 30.9903 25.6638 28.6409 28.2033 23.9739"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2586 12.8645H7.125V7.72949"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.7402 23.9741H28.8738V29.1092"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
