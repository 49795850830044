export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const links = {
  tgRoom: 'https://t.me/CratD2CSmartChain',
  tgChannel: 'https://t.me/cratd2cOfficial',
  discord: 'https://discord.gg/eyjrSFXUbW',
  linkedin: 'https://www.linkedin.com/company/cratd2csmartchain/',
  twitter: 'https://twitter.com/CratD2C',
  youtube: 'https://www.youtube.com/@CratD2CSmartChain',
  tiktok: 'https://www.tiktok.com/@cratd2csmartchain',
  medium: 'https://medium.com/@cratd2c',
  facebook: 'https://www.facebook.com/cratd2c',
};
