import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { Backdrop, Box } from '@mui/material';
import { COLOR_BG, Icons } from 'shared';

const LoaderContext = createContext(undefined);

export const LoaderProvider = ({ children }: PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loading = setTimeout(() => setIsLoading(false), 5000);
    return () => clearTimeout(loading);
  }, []);

  return (
    <LoaderContext.Provider value={undefined}>
      <Backdrop open={isLoading} sx={{ zIndex: 1199, background: COLOR_BG, transition: 'all 0.5s ease-out' }}>
        <Icons.CratLoader sx={{ width: { xs: 175, md: 325 } }} />
      </Backdrop>
      {children}
    </LoaderContext.Provider>
  );
};
