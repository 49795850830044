import { Box, Button, Card, Divider, Grid, Typography } from '@mui/material';
import { Background, COLOR_PRIMARY, useBreakpoints } from 'shared';

import { AboutText } from './ui';

export const Intro = () => {
  const { isDownMdLayout } = useBreakpoints();

  return (
    <Box component="section">
      <Typography
        className="no-resize"
        variant="h1"
        sx={{
          maxWidth: { xs: 255, sm: 615, md: 1080 },
          animation: '0.7s ease-out appear',
          fontSize: { sm: 72, xs: 30 },
        }}
      >
        CratD2C Decentralized Autonomous{' '}
        <Typography component="span" fontFamily="PP Editoral" fontStyle="italic" variant="inherit">
          SmartChain
        </Typography>
      </Typography>
      <Box mt={2} mb={{ xs: 5, md: 20 }}>
        <Typography
          component="p"
          variant="h5"
          fontWeight={400}
          className="secondary no-resize"
          mb={4.5}
          sx={{
            maxWidth: {
              xs: 255,
              sm: '100%',
            },
            animation: '0.7s ease-out appear',
          }}
        >
          Decentralizing Commerce, Building Communities
        </Typography>
        <Grid container rowSpacing={3.5}>
          <Grid item xs={12} sm={5} md={4.5}>
            <Card>
              <Grid container rowSpacing={2.5}>
                <Grid item xs={12} md={5.5}>
                  <Typography variant="h6" className="text-gray center medium">
                    IP Protection in
                  </Typography>
                  <Typography variant="h5" className="regular accent no-resize center">
                    181 Countries
                  </Typography>
                  <Typography variant="h6" className="text-gray center medium">
                    Reg. No.{' '}
                    <Box component="span" sx={{ color: COLOR_PRIMARY, whiteSpace: 'nowrap' }}>
                      EC-01-004149
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {!isDownMdLayout ? (
                    <Divider
                      orientation="vertical"
                      sx={{
                        height: '69px',
                      }}
                    />
                  ) : (
                    <Divider
                      orientation="horizontal"
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <Typography variant="h6" className="text-gray center medium">
                    IP Valuation:
                  </Typography>
                  <Typography variant="h5" className="regular accent no-resize center">
                    $160,255,384.00
                  </Typography>
                  <Typography variant="h6" className="text-gray center medium">
                    Assessment:{' '}
                    <Box component="span" sx={{ color: COLOR_PRIMARY, whiteSpace: 'nowrap' }}>
                      London Rate
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </Card>
            <Box sx={{ paddingBottom: 3 }} />
            <Grid container spacing={1.25}>
              <Grid item xs={12} sm={6}>
                <Button
                  size="large"
                  onClick={(e) => {
                    e.stopPropagation();
                    document.getElementById('price')?.scrollIntoView({ behavior: 'smooth' });
                  }}
                  sx={{ width: '100%', whiteSpace: 'nowrap' }}
                >
                  Buy CratD2C
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  size="large"
                  variant="outlined"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdrdJlZLXXfXr8Nexgo2y7CfBP-kPhsHD3tVh9kVXlE8ZHPsw/viewform?usp=pp_url"
                  target="_blank"
                  sx={{ width: '100%', whiteSpace: 'nowrap' }}
                >
                  Buy IP-Portion
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0} sm={1} md={1.5} />
          <Grid item xs={12} sm={6} md={6}>
            <AboutText />
          </Grid>
        </Grid>
      </Box>
      <Box
        component="img"
        src={Background}
        sx={{
          objectFit: 'cover',
          position: 'absolute',
          zIndex: -1,
          top: 0,
          height: { xs: 700, sm: 783 },
          left: { xs: -150, sm: 0 },
          width: { xs: 'calc(100vw + 150px)', sm: '100vw' },
          overflow: 'visible',
        }}
      />
    </Box>
  );
};
