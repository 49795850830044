import { BigNumber } from 'bignumber.js';
import { modalModel } from 'entities/modal';
import { userModel } from 'entities/user';
import { ContractsService, createAppAsyncThunk, logger, toDecimals } from 'shared';

import { actionTypes } from '../action-types';
import { ApproveArgs } from '../types';

export const approve = createAppAsyncThunk(
  actionTypes.APPROVE,
  async (
    { web3Provider, stableCoinAddress, amount, spender }: ApproveArgs,
    { rejectWithValue, dispatch, getState },
  ) => {
    dispatch(
      modalModel.actions.openModal({
        type: modalModel.ModalType.Approve,
        transition: 'fade',
      }),
    );

    const contractService = new ContractsService(web3Provider);
    const tokenContract = contractService.getErc20Contract(stableCoinAddress);
    const { address } = userModel.selectors.selectUserWeb3Info(getState());

    const allowance = await tokenContract.methods.allowance(address, spender).call();
    const gasPrice: string = await web3Provider.eth.getGasPrice();

    const handleApprove = async () => {
      // Reason: mm browser decreases approve for big values: e.g. 50000 -> 49999
      const errorApproveAmount = toDecimals(40000);
      let approveAmount;

      if (new BigNumber(amount).isGreaterThan(errorApproveAmount)) {
        const increasedAmount = new BigNumber(amount).multipliedBy(1.001).toFixed(0, 1);
        approveAmount = increasedAmount;
      } else {
        approveAmount = amount;
      }
      await tokenContract.methods.approve(spender, approveAmount).send({
        from: address,
        gasPrice,
      });
    };

    try {
      if (new BigNumber(allowance).isLessThan(amount)) {
        await handleApprove();
      }

      return allowance;
    } catch (err) {
      dispatch(
        modalModel.actions.openModal({
          type: modalModel.ModalType.ApproveReject,
          transition: 'fade',
        }),
      );
      logger('approve', err);
      return rejectWithValue(err);
    }
  },
);
