import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PersonSettings: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 32 31"
    sx={{
      width: '32px',
      height: '31px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M16.6788 2.388C18.6583 4.36758 18.6583 7.57711 16.6788 9.55669C14.6992 11.5363 11.4897 11.5363 9.51007 9.55669C7.5305 7.57711 7.5305 4.36758 9.51007 2.388C11.4897 0.408425 14.6992 0.408425 16.6788 2.388"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.4287 16.575C17.5431 15.8217 15.3308 15.4065 13.0953 15.4065C7.2192 15.4065 1.48242 18.2618 1.48242 22.6529V24.1046C1.48242 24.9058 2.13274 25.5562 2.93403 25.5562H16.5981"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.2561 29.9356C19.2482 29.9356 15.998 26.6869 15.998 22.6775C15.998 18.7523 19.3368 15.4151 23.2619 15.4194C27.2684 15.4223 30.5142 18.6711 30.5142 22.6775C30.5142 26.6854 27.2655 29.9356 23.2561 29.9356"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.2571 19.7742V20.7468"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.2571 24.6082V25.5807"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.7462 21.2258L21.5882 21.7048"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.941 23.6501L25.7684 24.1291"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.7462 24.1291L21.5882 23.6501"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.941 21.7048L25.7684 21.2258"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.626 21.3081C25.3823 22.0644 25.3823 23.2905 24.626 24.0467C23.8698 24.8029 22.6437 24.8029 21.8875 24.0467C21.1313 23.2905 21.1313 22.0644 21.8875 21.3081C22.6437 20.5519 23.8698 20.5519 24.626 21.3081"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
