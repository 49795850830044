import { useCallback } from 'react';
import { crowdsaleModel } from 'entities/crowdsale';
import { useAppDispatch, useShallowSelector } from 'shared';
import { useWalletConnectorContext } from 'shared/config/wallet-connect/hooks/useWeb3Provider';

export const useRetryBuy = () => {
  const dispatch = useAppDispatch();
  const { walletService } = useWalletConnectorContext();
  const buyData = useShallowSelector(crowdsaleModel.selectors.getProp('currentBuyData'));

  const retryBuy = useCallback(() => {
    if (buyData) {
      dispatch(crowdsaleModel.thunks.buyTokens({ ...buyData, web3Provider: walletService.Web3() }));
    }
  }, [buyData, dispatch, walletService]);

  return retryBuy;
};
