import { Box, IconButton, Modal } from '@mui/material';
import { BORDER_RADIUS_L, Icons } from 'shared';

type VideoModalProps = {
  title: string;
  videoSrc: string;
  isOpen: boolean;
  onClose: () => void;
};

export const VideoModal = ({ isOpen, title, videoSrc, onClose }: VideoModalProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      disableRestoreFocus
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box
        onKeyDown={(e) => e.key === 'Escape' && onClose()}
        sx={{
          p: { xs: 2, shapeMargin: 5, lg: 7 },
          iframe: {
            border: 'none',
            borderRadius: BORDER_RADIUS_L,
            width: { xs: 325, sm: 540, md: 940, lg: 1298, xl: 1632 },
            height: { xs: 182, sm: 303, md: 551, lg: 730, xl: 918 },
          },
        }}
      >
        {isOpen && (
          <IconButton onClick={onClose} sx={{ position: 'absolute', top: 20, right: 20, zIndex: 10 }}>
            <Icons.Close />
          </IconButton>
        )}
        <iframe title={title} src={videoSrc} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen />
      </Box>
    </Modal>
  );
};
