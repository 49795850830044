import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

export const getMuiContainerOverrides = (theme: Theme): ComponentsOverrides['MuiContainer'] => ({
  root: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
    },
  },
});

export const getMuiContainerDefaultProps = (): ComponentsProps['MuiContainer'] => ({
  sx: {
    mx: 'auto',
  },
});
