import React from 'react';
import { Box, Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, ModalSidebar } from 'shared';

import { list } from './constants';

export interface EstateTradingModalPayload {
  data?: null;
  type: ModalType.EstateTrading;
  isOpen?: boolean;
}

export const EstateTradingModal = React.forwardRef<
  HTMLElement,
  Pick<EstateTradingModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar
      isOpen={isOpen}
      ref={ref}
      onClose={onClose}
      title="CratD2C Real Estate Trading Portal"
      icon={<Icons.Building />}
    >
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit" fontWeight={700}>
        CratD2C decentralized real estate trading portal is an online platform built on Decentralized Autonomous Smart
        Chain (DASC) technology that enables buyers, sellers, and other stakeholders to interact directly without
        intermediaries, leveraging a series of interconnected modules that streamline property transactions and ensure
        transparency. Here&#39;s an outline of the key components and features of the CratD2C decentralized real estate
        trading portal:
      </Typography>
      <br />
      <Box component="ol">
        {list.map((item, i) => {
          return (
            <React.Fragment key={item.id}>
              <Typography
                component="li"
                variant="inherit"
                color="inherit"
                fontFamily="inherit"
                sx={{
                  position: 'relative',
                  paddingLeft: '24px',
                  '&:before': {
                    content: `"${i + 1}."`,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    fontSize: 'inherit',
                    fontFamily: 'inherit',
                  },
                }}
              >
                {item.text}
              </Typography>
              <br />
            </React.Fragment>
          );
        })}
      </Box>
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        In summary, CratD2C decentralized real estate trading portal leverages blockchain technology and smart contracts
        to create a secure, transparent, and efficient platform for property trading. By eliminating intermediaries and
        empowering users, this structure can revolutionise how we buy, sell, and invest in real estate.
      </Typography>
    </ModalSidebar>
  );
});
