import React from 'react';
import { Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, ModalSidebar } from 'shared';

export interface PaymentApiModalPayload {
  data?: null;
  type: ModalType.PaymentApi;
  isOpen?: boolean;
}

export const PaymentApiModal = React.forwardRef<
  HTMLElement,
  Pick<PaymentApiModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar isOpen={isOpen} ref={ref} onClose={onClose} title="API" icon={<Icons.Settings />}>
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        API stands for Application Programming Interface, a programmatic instruction set allowing one program to
        interface.
      </Typography>
      <br />
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        In other words, an API authorizes and integrates two applications to communicate to provide a seamless digital
        experience, payment or remittance.
      </Typography>
      <br />
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        CratD2C payment API, also known as CratD2C Payment Gateway API or CratD2C Payment Processor, operates to
        integrate a payment solution with any other existing applications, such as connecting a business’s checkout
        function to the payment system and allowing business merchants to accept payments from customers on-site,
        avoiding the risk of shopping cart abandonment.
      </Typography>
      <br />
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        Effecting business flexibility provides security and convenience for merchants and customers.
      </Typography>
    </ModalSidebar>
  );
});
