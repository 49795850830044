import { BookingPortal, ECommercePortal, EstatePortal, PaymentGateway } from '../../../assets';

export const useCasesData = [
  {
    id: 1,
    subtitle: 'E-Commerce Trading Platform',
    image: ECommercePortal,
    src: 'https://player.vimeo.com/video/918239443',
    info: {
      title: 'e-Commerce MarketCap',
      value: '$2.562 T',
      placement: 'top-left',
    },
  },
  {
    id: 2,
    subtitle: 'Luxury Lifestyle Booking',
    image: BookingPortal,
    src: 'https://player.vimeo.com/video/919783205',
    info: {
      title: 'Tourism MarketCap',
      value: '$13.39 Т',
      placement: 'top-right',
    },
  },
  {
    id: 3,
    subtitle: 'Real Estate Trading Portal',
    image: EstatePortal,
    src: 'https://player.vimeo.com/video/918715625',
    info: {
      title: 'Real Estate MarketCap',
      value: '$9.8 T',
      placement: 'bottom-left',
    },
  },
  {
    id: 4,
    subtitle: 'Universal Payment Gateway',
    image: PaymentGateway,
    src: 'https://player.vimeo.com/video/915626757',
    info: {
      title: 'Cryptocurrency MarketCap',
      value: '$2.32 Т',
      placement: 'bottom-right',
    },
  },
];
