import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Facebook: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 24 24"
    sx={{
      width: '24px',
      height: '24px',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M13.641 22.3717V15.0345H16.0875L16.5532 11.9993H13.641V10.0297C13.641 9.1995 14.0475 8.39025 15.3517 8.39025H16.6755V5.8065C16.6755 5.8065 15.474 5.60175 14.325 5.60175C11.9265 5.60175 10.359 7.05525 10.359 9.687V12H7.69275V15.0353H10.359V22.3725C5.33925 21.585 1.5 17.241 1.5 12C1.5 6.201 6.201 1.5 12 1.5C17.799 1.5 22.5 6.201 22.5 12C22.5 17.241 18.66 21.585 13.641 22.3725V22.3717Z"
      fill="#currentColor"
    />
  </SvgIcon>
);
