import { Box } from '@mui/material';
import { Icons } from 'shared';

import { COLOR_STROKE } from '../colors';

const Arrow = () => <Icons.ArrowChevron sx={{ rotate: '90deg' }} />;
export const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        '.collapsIconWrapper': {
          svg: { color: COLOR_STROKE },
        },
        '.Mui-expanded & > .collapsIconWrapper': {
          display: 'none',
        },
        '.expandIconWrapper': {
          display: 'none',
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'block',
        },
      }}
    >
      <div className="expandIconWrapper">
        <Arrow />
      </div>
      <div className="collapsIconWrapper">
        <Arrow />
      </div>
    </Box>
  );
};
