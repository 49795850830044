import { COLOR_BG, COLOR_BLACK, COLOR_PRIMARY, COLOR_STROKE, COLOR_WH } from './colors.constants';
import {
  colorsButton,
  colorsCard,
  colorsDivider,
  colorsIconButton,
  colorsLinearProgress,
  colorsMenu,
  colorsMenuItem,
  colorsRadioAndCheckbox,
  colorsSelect,
  colorsTextField,
} from './components';

export const colorThemes = {
  background: COLOR_BG,
  mainColor: COLOR_PRIMARY,
  borderMain: COLOR_STROKE,
  white: COLOR_WH,
  black: COLOR_BLACK,
  ...colorsButton,
  ...colorsIconButton,
  ...colorsRadioAndCheckbox,
  ...colorsTextField,
  ...colorsCard,
  ...colorsLinearProgress,
  ...colorsDivider,
  ...colorsSelect,
  ...colorsMenu,
  ...colorsMenuItem,
};
