import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Exclamation: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 100 100"
    sx={{
      width: '100px',
      height: '100px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <rect x="45" y="23" width="9" height="39" fill="#B4EB56" />
    <rect x="45" y="69" width="9" height="9" fill="#B4EB56" />
    <rect x="1" y="1" width="98" height="98" rx="49" stroke="#B4EB56" strokeWidth="2" />
  </SvgIcon>
);
