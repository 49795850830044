import { Box, Card, Stack, Typography } from '@mui/material';
import { userModel } from 'entities/user';
import { CratLogo, fromDecimals, useShallowSelector } from 'shared';

export const BalanceCard = () => {
  const { cratBalance } = useShallowSelector(userModel.selectors.selectUserCrowdsaleInfo);
  const balance = fromDecimals(cratBalance, 8);

  return (
    <Card sx={(theme) => ({ p: theme.spacing('18px', '13px'), mt: 2, width: 230, height: 116 })}>
      <Stack alignItems="center" justifyContent="center" flexGrow={1} spacing={1} sx={{ height: '100%' }}>
        <Typography variant="h6" className="secondary center medium">
          My Balance
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            src={CratLogo}
            component="img"
            sx={{
              width: 32,
            }}
          />
          <Typography variant="h3" className="no-resize">
            {balance}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
