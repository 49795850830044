export const list = [
  {
    title: '$20,000 PRE-SEED PACKAGE',
    items: [
      { id: '1', text: '25% BONUS in CratD2C-Pre tokens' },
      { id: '2', text: '50% BONUS for the entire Campaign period of CratD2C IP-Share SEED ROUND' },
      { id: '3', text: 'Validator status' },
    ],
  },
];
