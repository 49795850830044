import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Buy: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 36 36"
    sx={{
      width: '36px',
      height: '36px',
      fill: 'none',
      color: '#B4EB56',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M28.3235 10.0161C27.9897 9.58203 27.6355 9.15962 27.2377 8.76187C22.1353 3.65945 13.864 3.65945 8.76159 8.76187C8.36385 9.15962 8.0111 9.58203 7.67578 10.0161"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.67578 25.9839C8.00965 26.4179 8.36385 26.8403 8.76159 27.2381C13.864 32.3405 22.1353 32.3405 27.2377 27.2381C27.6355 26.8403 27.9882 26.4179 28.3235 25.9839"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0455 21.7321H5.66016V18.0581H10.0426C11.0573 18.0581 11.8803 18.8812 11.8803 19.8958V19.8958C11.8818 20.9105 11.0587 21.7321 10.0455 21.7321Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.66016 18.058V14.2678H9.57951C10.6261 14.2678 11.4753 15.117 11.4753 16.1636V16.1636C11.4753 17.2102 10.6261 18.0594 9.57951 18.0594"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.5684 14.2678L28.6792 18.4993L31.79 14.2678"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.6828 21.6724V18.4934"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.8368 14.2678V18.6227C21.8368 20.3399 20.4448 21.7335 18.726 21.7335V21.7335C17.0088 21.7335 15.6152 20.3414 15.6152 18.6227V14.2678"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
