import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { CssBaseline } from '@mui/material';
import { ModalContainer } from 'entities/modal';
import { Routing } from 'pages';
import { Layout, ScrollToTop } from 'widgets';

import { withPersistor, withProviders, withRouter, withStore, withTheme, WithWalletConnect } from './providers';

import 'react-toastify/dist/ReactToastify.css';
import './appStyles/index.scss';

const App = () => {
  useEffect(() => {
    window.history.scrollRestoration = 'manual'; // scroll to top after resfresh
  }, []);

  return (
    <>
      <Layout>
        <Routing />
      </Layout>
      <ScrollToTop />
      <ModalContainer />
      <CssBaseline />
      <ToastContainer autoClose={10000} hideProgressBar position="bottom-right" closeButton />
    </>
  );
};

export default withProviders(withRouter, withStore, withPersistor, withTheme, WithWalletConnect)(App);
