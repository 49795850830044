import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Youtube: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 25 25"
    sx={{
      width: '25px',
      height: '25px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M22.5617 7.44539C22.3202 6.54164 21.6085 5.83064 20.7055 5.58914C19.0675 5.15039 12.5012 5.15039 12.5012 5.15039C12.5012 5.15039 5.9342 5.15039 4.29695 5.58914C3.3932 5.83064 2.6822 6.54239 2.4407 7.44539C2.00195 9.08339 2.00195 12.4996 2.00195 12.4996C2.00195 12.4996 2.00195 15.9166 2.4407 17.5539C2.6822 18.4576 3.39395 19.1686 4.29695 19.4101C5.93495 19.8489 12.5012 19.8489 12.5012 19.8489C12.5012 19.8489 19.0682 19.8489 20.7055 19.4101C21.6092 19.1686 22.3202 18.4569 22.5617 17.5539C23.0005 15.9159 23.0005 12.4996 23.0005 12.4996C23.0005 12.4996 23.0005 9.08264 22.5617 7.44539ZM10.4005 15.6496V9.34964L15.856 12.4996L10.4005 15.6496Z"
      fill="currentColor"
    />
  </SvgIcon>
);
