import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Chains, ChainType, isMainnet, WalletProviders } from 'shared/config';

import { getUserInfo } from './thunks';

export type UserState = {
  address: string;
  provider: WalletProviders;
  chainType: ChainType;
  isAdmin: boolean;
  network: Chains;
  balance: string;

  cratBalance: string;
  usdtBalance: string;
  usdcBalance: string;

  totalSpend: string;
  tier: string;
  bonusTokensReceived: string;
  referralReceived: string;
  referralFather: string;
};

const initialUserState: UserState = {
  address: '',
  provider: WalletProviders.metamask,
  isAdmin: false,
  chainType: isMainnet ? 'mainnet' : 'testnet', // TODO: change to mainnet on production
  network: Chains.BSC,
  balance: '0',

  cratBalance: '0',
  usdtBalance: '0',
  usdcBalance: '0',

  totalSpend: '0',
  tier: '0',
  bonusTokensReceived: '0',
  referralReceived: '0',
  referralFather: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    updateUserState: (state: UserState, action: PayloadAction<Partial<UserState>>) => ({
      ...state,
      ...action.payload,
    }),

    clearUserState: () => ({
      ...initialUserState,
    }),

    disconnectWalletState: () => {
      localStorage.removeItem('walletconnect');
      return {
        ...initialUserState,
      };
    },
  },

  extraReducers(builder) {
    builder.addCase(getUserInfo.fulfilled, (state, { payload }) => {
      if (!payload) return state;
      const { ...balances } = payload;
      return {
        ...state,
        ...balances,
      };
    });
  },
});

export const { reducer } = userSlice;
export const { actions } = userSlice;
