import { PropsWithChildren } from 'react';
import { Container, Stack } from '@mui/material';

import { LoaderProvider } from '../providers';

import { Footer } from './footer';
import { Header } from './header';

export const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack flexGrow={1}>
      <LoaderProvider>
        <Header />
        <Container
          sx={{
            pt: 2,
            flex: 1,
          }}
        >
          {children}
        </Container>
        <Footer />
      </LoaderProvider>
    </Stack>
  );
};
