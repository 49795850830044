interface TextExpanderProps {
  text: string;
  isExpanded: boolean;
  maxLength?: number;
}

export const TextExpander = ({ text = '', isExpanded, maxLength = 50 }: TextExpanderProps) => {
  const isLarge = !!text && text.length > maxLength;
  const substring = `${text?.substring(0, maxLength).trim()}${isLarge ? `...` : ''}`;

  return <span>{isExpanded ? text : substring}</span>;
};
