import { Icons, links } from 'shared';

export const navLinks = [
  {
    id: 1,
    title: 'Smart-Chain',
    path: 'https://cratd2csmartchain.io/smartchain',
    isExternal: true,
  },
  {
    id: 2,
    title: 'Use Cases',
    path: 'https://cratd2csmartchain.io/use-cases',
    isExternal: true,
  },
  {
    id: 3,
    title: 'White Paper',
    path: 'https://cratd2csmartchain.io/pdf/whitepaper.pdf',
    isExternal: true,
  },
  {
    id: 5,
    title: 'Connect with Team',
    path: 'https://cratd2csmartchain.io/connect/project-team',
    isExternal: true,
  },
];

export const socials = [
  {
    Icon: Icons.Telegram,
    url: links.tgRoom,
    title: 'Community Chat',
    description: 'Ask general questions and chat with the worldwide community on Telegram.',
  },
  {
    Icon: Icons.Discord,
    url: links.discord,
    title: 'Developer Chat',
    description: 'Have technical questions about CratD2C tools? Ask a developer on the Community Discord.',
  },
  {
    Icon: Icons.Twitter,
    url: links.twitter,
    title: 'Twitter',
    description: 'Follow @CratD2C to get the latest news and updates from across the ecosystem.',
  },
  {
    Icon: Icons.Linkedin,
    url: links.linkedin,
    title: 'Linked in',
    description: 'cratd2csmartchain',
  },
  {
    Icon: Icons.Youtube,
    url: links.youtube,
    title: 'Youtube',
    description: 'cratd2csmartchain',
  },
  {
    Icon: Icons.Facebook,
    url: links.facebook,
    title: 'Facebook',
    description: 'cratd2csmartchain',
  },
];

export const footerNavLinks = [
  {
    id: 21,
    title: 'Terms&Conditions',
    path: 'https://cratd2csmartchain.io/terms-and-conditions',
    isExternal: true,
  },
];
