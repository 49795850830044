import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, Modal } from 'shared';

import { useRetryBuy } from '../../lib';

export interface SendRejectModalPayload {
  data?: null;
  type: ModalType.SendReject;
  isOpen?: boolean;
}

export const SendRejectModal = React.forwardRef<
  HTMLElement,
  Pick<SendRejectModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose }, ref) => {
  const retry = useRetryBuy();

  return (
    <Modal ref={ref} showClose onClose={onClose}>
      <Stack alignItems="center">
        <Typography variant="h5" component="span" color="inherit" className="no-resize">
          STEP 2/2{' '}
          <Typography variant="inherit" component="span" className="accent">
            SEND
          </Typography>
        </Typography>
        <Icons.Exclamation
          sx={{
            mt: 4,
            mb: 3,
          }}
        />
        <Typography
          variant="h5"
          className="center"
          component="span"
          color="inherit"
          fontSize={18}
          sx={{
            maxWidth: 408,
          }}
        >
          You rejected Send transaction in Metamask. Press Send again to start over or close this window.
        </Typography>
        <Button
          onClick={retry}
          sx={{
            mt: 4,
          }}
        >
          Send again
        </Button>
      </Stack>
    </Modal>
  );
});
