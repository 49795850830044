import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Logout: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 24 22"
    sx={{
      fill: 'none',
      width: '24px',
      height: '22px',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M17.8333 15.6665L22.5 10.9998M22.5 10.9998L17.8333 6.33317M22.5 10.9998L6.16667 10.9998M13.1667 15.6665V16.8332C13.1667 18.7662 11.5997 20.3332 9.66667 20.3332H5C3.067 20.3332 1.5 18.7662 1.5 16.8332V5.1665C1.5 3.23351 3.067 1.6665 5 1.6665H9.66667C11.5997 1.6665 13.1667 3.23351 13.1667 5.1665V6.33317"
      stroke="#1A1A1A"
      strokeWidth="2.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
