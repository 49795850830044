import { useCallback, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { TextExpander } from 'shared';
import { FontWeights } from 'shared/config/theme/Typography';

import { TEXT_CONTENT } from '../constants';

const TEXT_SX: SystemStyleObject = { fontSize: '14px', fontWeight: FontWeights.SemiBold, whiteSpace: 'pre-line' };

const BUTTON_SX: SystemStyleObject = {
  mt: { sm: 3, xs: 2 },
  mb: { xs: 4, sm: 0 },
  '& > *': {
    textDecoration: 'underline',
  },
};

export const AboutText = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleTextExpand = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  return (
    <>
      <Typography variant="h6" sx={TEXT_SX}>
        <TextExpander isExpanded={isExpanded} maxLength={450} text={TEXT_CONTENT} />
      </Typography>
      <Button onClick={toggleTextExpand} variant="text" className="secondary" size="medium" sx={BUTTON_SX}>
        <span>{isExpanded ? 'Roll up' : 'Read more'}</span>
      </Button>
    </>
  );
};
