import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Diamond: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 36 35"
    sx={{
      width: '36px',
      height: '35px',
      fill: 'none',
      color: '#B4EB56',
      ...sx,
    }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0272 31.5102L32.0888 18.4457C32.6564 17.8781 32.6564 16.9578 32.0888 16.3888L19.0272 3.32425C18.4625 2.75813 17.5364 2.75813 16.9717 3.32425L3.91006 16.3888C3.34248 16.9564 3.34248 17.8767 3.91006 18.4457L16.9717 31.5102C17.5364 32.0749 18.4625 32.0749 19.0272 31.5102Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3322 31.3257L23.8063 18.6735L18.3424 3.23266C18.2277 2.9104 17.7719 2.9104 17.6587 3.23266L12.1934 18.6735L17.6674 31.3272C17.7922 31.6175 18.2074 31.6175 18.3322 31.3257V31.3257Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.49023 17.3787L12.9678 18.929H23.0304L32.508 17.3787"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
