import React from 'react';
import { Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, ModalSidebar } from 'shared';

export interface CratNewModalPayload {
  data?: null;
  type: ModalType.CratNew;
  isOpen?: boolean;
}

export const CratNewModal = React.forwardRef<
  HTMLElement,
  Pick<CratNewModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar isOpen={isOpen} ref={ref} onClose={onClose} title="CratD2C Smart-Chain" icon={<Icons.CratThin />}>
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        CratD2C Decentralized Autonomous Smart Chain is an innovative technology that combines smart contracts,
        consensus mechanisms, and token economics to enable various use cases and applications. It removes the need for
        centralized control, enabling self-governance, transparency, and security. CratD2C smart contracts automate the
        network&#39;s processes, rules, and agreements, allowing the D.A.S.C to function efficiently and adapt to
        changing conditions. The smart chain can host decentralized applications (dApps) and tokens, facilitating and
        connecting various use cases from manufacturers to end users in different industries, such as (Manufacturing and
        Logistics, e-Commerce Supply Chain, Real Estate, Tourism, Intellectual Property and Content Creation, Energy,
        Insurance, Agriculture and Food Supply) & much more.
      </Typography>

      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        It&#39;s also important to note that CratD2C Decentralized Autonomous Smart Chain (D.A.S.C) has six (6) primary
        dApps built on it.
      </Typography>

      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        Distributed Trust Ledger (DTL), Decentralized Autonomous Community (DAC), Community Trust Vesting Grant (CTVG),
        CratD2C eCommerce Trading Portal, CratD2C Real Estate Trading Portal, CRAT Luxury LifeStyle Booking Portal.
      </Typography>

      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        The decentralization in the CratD2C (D.A.S.C) is built on a distributed network of nodes that work together to
        maintain and validate activities on the blockchain. This decentralization ensures no single entity can control
        or manipulate the system, providing increased security and resilience against attacks or failures with
        decentralized features.
      </Typography>
    </ModalSidebar>
  );
});
