import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { COLOR_GRAY_4, Icons, Modal } from 'shared';

import { useRetryBuy } from '../../lib';

export interface ApproveRejectModalPayload {
  data?: null;
  type: ModalType.ApproveReject;
  isOpen?: boolean;
}

export const ApproveRejectModal = React.forwardRef<
  HTMLElement,
  Pick<ApproveRejectModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose }, ref) => {
  const retry = useRetryBuy();

  return (
    <Modal ref={ref} showClose onClose={onClose}>
      <Stack alignItems="center">
        <Typography variant="h5" component="span" color="inherit" className="no-resize">
          STEP 1/2{' '}
          <Typography variant="inherit" component="span" className="accent">
            Approve
          </Typography>
        </Typography>
        <Icons.Exclamation
          sx={{
            mt: 4,
            mb: 3,
          }}
        />
        <Typography
          variant="h5"
          className="center"
          component="span"
          color="inherit"
          fontSize={18}
          sx={{
            maxWidth: 540,
          }}
        >
          You rejected Approve transaction in Metamask. Press Approve again to start over or close this window.
        </Typography>
        <Stack
          sx={{
            mt: 3,
            maxWidth: 445,
            width: '100%',
            color: COLOR_GRAY_4,
          }}
        >
          <Typography variant="body2" color="inherit" className="center">
            ERC-20 tokens are deployed with functionality that allows other smart contracts to move tokens.
          </Typography>
        </Stack>
        <Button
          onClick={retry}
          sx={{
            mt: 3,
            maxWidth: 225,
          }}
        >
          Approve again
        </Button>
      </Stack>
    </Modal>
  );
});
