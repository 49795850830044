import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Telegram: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 20 16"
    sx={{
      width: '22px',
      height: '17px',
      ...sx,
    }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.80615 6.8879C6.90641 4.62993 10.3074 3.14134 12.0091 2.42212C16.8677 0.36862 17.8773 0.0119045 18.5354 0.00012559C18.6801 -0.00246506 19.0037 0.0339819 19.2133 0.206816C19.3903 0.352754 19.439 0.549895 19.4623 0.68826C19.4856 0.826626 19.5146 1.14183 19.4916 1.38811C19.2283 4.1992 18.089 11.021 17.5094 14.1694C17.2642 15.5017 16.7813 15.9484 16.3138 15.9921C15.2978 16.0871 14.5263 15.3098 13.5422 14.6543C12.0024 13.6287 11.1325 12.9902 9.63785 11.9893C7.9105 10.8327 9.03027 10.1969 10.0147 9.15799C10.2723 8.88609 14.7488 4.74867 14.8354 4.37335C14.8463 4.32641 14.8563 4.15143 14.754 4.05904C14.6517 3.96664 14.5007 3.99824 14.3918 4.02337C14.2373 4.05898 11.7775 5.71112 7.01212 8.97978C6.31389 9.46697 5.68145 9.70435 5.11481 9.69191C4.49014 9.6782 3.28851 9.33301 2.39523 9.03795C1.29958 8.67605 0.428776 8.48471 0.504603 7.87009C0.544098 7.54996 0.977948 7.22256 1.80615 6.8879Z"
      fill="currentColor"
    />
  </SvgIcon>
);
