import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiRadio = (theme: Theme): ComponentsOverrides['MuiCheckbox'] => {
  const colorCommon = {
    color: theme.themeColors.colorRadioAndCheckboxBorder,
    '&.Mui-checked': {
      color: theme.themeColors.colorRadioAndCheckboxChecked,
    },
  };
  return {
    root: {
      '& .MuiSvgIcon-root': {
        fontSize: 32,
      },
    },
    colorPrimary: colorCommon,
    colorSecondary: colorCommon,
  };
};

export const getMuiRadioDefaultProps = (): ComponentsProps['MuiCheckbox'] => ({
  color: 'primary',
});
