import React from 'react';
import { Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, ModalSidebar } from 'shared';

export interface VendingMachineModalPayload {
  data?: null;
  type: ModalType.VendingMachine;
  isOpen?: boolean;
}

export const VendingMachineModal = React.forwardRef<
  HTMLElement,
  Pick<VendingMachineModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar isOpen={isOpen} ref={ref} onClose={onClose} title="Vending Machine" icon={<Icons.Display />}>
      <Typography variant="inherit" color="inherit" fontFamily="inherit">
        CratD2C Automated Vending Machine is designed to provide a user-friendly point-of-sale purchase system for daily
        consumer needs using CratD2C Smart Chain Applications and CratD2C Coin as means of payment. eCommerce merchants
        worldwide can now have enormous options with various products: snacks, beverages, pizzas, cupcakes, newspapers,
        tickets, etc.
      </Typography>
      <br />
      <Typography variant="inherit" color="inherit" fontFamily="inherit">
        CratD2C Vending Machine dispenses a product to the users with payment in equivalent in CratD2C Coin and product
        selection.
      </Typography>
      <br />
      <Typography variant="inherit" color="inherit" fontFamily="inherit">
        CratD2C Vending Machines works 24x7 at any standalone unit store worldwide and can be franchised by contract
        within any city in the world. Its primary function is to issue users with a diverse range of products flawlessly
        at any time.
      </Typography>
    </ModalSidebar>
  );
});
