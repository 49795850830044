import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { Icons } from 'shared/ui';

import { TRANSITION_300 } from '../common';
import { FontFamilies, FontWeights } from '../Typography';

export const getMuiSelectOverrides = (theme: Theme): ComponentsOverrides['MuiSelect'] => ({
  select: {
    fontFamily: FontFamilies.primary,
    fontWeight: FontWeights.Regular,
    fontSize: 16,
    lineHeight: '26px',
    color: theme.themeColors.colorsSelectColor,
    padding: '0',
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    transition: TRANSITION_300,
    color: theme.themeColors.colorsSelectColor,
  },

  iconOpen: {
    transform: 'translateY(-55%) rotate(180deg)',
  },
});

export const getMuiSelectDefaultProps = (): ComponentsProps['MuiSelect'] => ({
  IconComponent: Icons.Chevron,
  size: 'medium',
  variant: 'filled',
  color: 'primary',
  displayEmpty: true,
  disableUnderline: true,
});
