import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { COLOR_BLACK, ModalSidebar, scrollToElement } from 'shared';

import { list } from './constants';

export interface ValidatorModalPayload {
  data?: null;
  type: ModalType.Validator;
  isOpen?: boolean;
}

export const ValidatorModal = React.forwardRef<
  HTMLElement,
  Pick<ValidatorModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  const onBuyClick = () => {
    onClose();
    scrollToElement('crowdsale', 'start');
  };

  return (
    <ModalSidebar
      isOpen={isOpen}
      ref={ref}
      onClose={onClose}
      title="Become a Validator"
      titleSx={{
        maxWidth: 200,
        fontSize: { xs: 30, sm: 40 },
      }}
    >
      <Typography className="black no-resize" variant="h5" lineHeight="25px">
        {list.map((section) => {
          return (
            <>
              <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit" fontWeight={700}>
                {section.title}
              </Typography>
              <br />
              <Box component="ul">
                {section.items.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <Typography
                        component="li"
                        variant="inherit"
                        color="inherit"
                        fontFamily="inherit"
                        sx={{
                          position: 'relative',
                          paddingLeft: '21px',
                          '&:before': {
                            content: "''",
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            borderRadius: '50%',
                            width: 5,
                            height: 5,
                            background: COLOR_BLACK,
                            mt: 1.25,
                            ml: 0.5,
                          },
                        }}
                      >
                        {item.text}
                      </Typography>
                      <br />
                    </React.Fragment>
                  );
                })}
              </Box>
              <Button
                onClick={onBuyClick}
                sx={{
                  maxWidth: 150,
                  width: '100%',
                }}
              >
                Buy
              </Button>
              <br />
            </>
          );
        })}
      </Typography>
    </ModalSidebar>
  );
});
