import { Box, SxProps, Typography } from '@mui/material';
import { BORDER_RADIUS_L, Icons, InfoCircle, useModal, VideoModal } from 'shared';

type VideoPreviewCardProps = {
  subtitle: string;
  image: string;
  videoSrc: string;
  info: { title: string; value: string; placement: string };
  boxSxProps?: SxProps;
};

export const VideoPreviewCard = ({ subtitle, image, videoSrc, info, boxSxProps }: VideoPreviewCardProps) => {
  const [isMenuVisible, onOpenMenu, onCloseMenu] = useModal(false);

  return (
    <>
      <Box
        onClick={onOpenMenu}
        borderRadius={BORDER_RADIUS_L}
        overflow="hidden"
        height={{ xs: 184, sm: 300, lg: 350 }}
        width="100%"
        position="relative"
        sx={{ '&:hover': { cursor: 'pointer' }, ...boxSxProps }}
      >
        <InfoCircle placement={info.placement} reaignDownMd>
          <Typography mt={0.5} className="center">
            {info.title}
          </Typography>
          <Typography className="accent medium" fontSize={20}>
            {info.value}
          </Typography>
        </InfoCircle>
        <Icons.Play
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            translate: '-50% -50%',
            width: { xs: 40, md: 80 },
            height: { xs: 40, md: 80 },
          }}
        />
        <Box component="img" src={image} sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </Box>

      <VideoModal isOpen={isMenuVisible} title={subtitle} videoSrc={videoSrc} onClose={onCloseMenu} />
    </>
  );
};
