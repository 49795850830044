import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Polygon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 93 106"
    sx={{
      width: '93px',
      height: '106px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M42.734 1.76489C45.0626 0.412782 47.9374 0.412784 50.266 1.76489L88.766 24.1197C91.0775 25.4619 92.5 27.9328 92.5 30.6056V75.3943C92.5 78.0672 91.0775 80.5381 88.766 81.8803L50.266 104.235C47.9374 105.587 45.0626 105.587 42.734 104.235L4.23398 81.8803C1.92251 80.5381 0.5 78.0672 0.5 75.3943V30.6057C0.5 27.9328 1.92251 25.4619 4.23398 24.1197L42.734 1.76489Z"
      stroke="currentColor"
    />
  </SvgIcon>
);
