import {
  CertikLogo,
  CoinstoreLogo,
  FasterCapitalLogo,
  HackenLogo,
  IntellectLawyerLogo,
  InterocoLogo,
  JoorneyLogo,
  LbankLogo,
  LondonRateLogo,
  MexcLogo,
  MulgaLogo,
  RnbLogo,
} from 'shared';

export const partners = [
  { id: 1, subtitle: 'Official Partners', image: RnbLogo, maxHeight: 25 },
  { id: 2, image: JoorneyLogo, maxHeight: 41 },
  { id: 3, url: 'https://skynet.certik.com/projects/cratd2c', subtitle: 'Audit', image: CertikLogo, maxHeight: 36 },
  { id: 4, url: 'https://audits.hacken.io/cratd2c', image: HackenLogo, maxHeight: 28 },
  { id: 5, subtitle: 'Backers', image: MulgaLogo, maxHeight: 56 },
  { id: 6, image: FasterCapitalLogo, maxHeight: 26 },
  { id: 7, url: 'https://www.mexc.com', subtitle: 'Pre-confirmed listing', image: MexcLogo, maxHeight: 21 },
  { id: 8, url: 'https://www.lbank.com', image: LbankLogo, maxHeight: 28 },
  { id: 9, url: 'https://www.coinstore.com', image: CoinstoreLogo, maxHeight: 38 },
  { id: 10, subtitle: 'IP Certification', image: InterocoLogo, maxHeight: 55 },
  { id: 11, subtitle: 'Valuation', image: LondonRateLogo, maxHeight: 65 },
  { id: 12, subtitle: 'Legal', image: IntellectLawyerLogo, maxHeight: 47 },
];
