import { modalModel } from 'entities/modal';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

// const userActionTypes = Object.values(userActions).map((action) => action.type);

export const ignoredActions = [
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  modalModel.actions.openModal.type,
  modalModel.actions.closeModal.type,
];
