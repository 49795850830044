import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Wallet: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 18 16"
    sx={{
      width: '18px',
      height: '16px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M16.1113 3.55556V1.77778C16.1113 0.797333 15.314 0 14.3336 0H2.778C1.30777 0 0.111328 1.19644 0.111328 2.66667V13.3333C0.111328 15.2898 1.70599 16 2.778 16H16.1113C17.0918 16 17.8891 15.2027 17.8891 14.2222V5.33333C17.8891 4.35289 17.0918 3.55556 16.1113 3.55556ZM2.778 1.77778H14.3336V3.55556H2.778C2.54912 3.54532 2.33302 3.44719 2.17469 3.28162C2.01635 3.11604 1.92798 2.89577 1.92798 2.66667C1.92798 2.43757 2.01635 2.2173 2.17469 2.05172C2.33302 1.88614 2.54912 1.78802 2.778 1.77778ZM16.1113 14.2222H2.78866C2.378 14.2116 1.88911 14.0489 1.88911 13.3333V5.16889C2.16822 5.26933 2.46422 5.33333 2.778 5.33333H16.1113V14.2222Z"
      fill="currentColor"
    />
  </SvgIcon>
);
