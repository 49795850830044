import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ConfirmCircleGreen: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 15 15"
    sx={{
      width: '15px',
      height: '15px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <circle cx="7.5" cy="7.5" r="7.5" fill="#B4EB56" />
    <path
      d="M3.125 8.125L5.625 10.625L11.875 4.375"
      stroke="#1A1A1A"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
