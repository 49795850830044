import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Twitter: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 20 17"
    sx={{
      width: '20px',
      height: '17px',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M6.2918 16.6246C13.8371 16.6246 17.9652 10.3719 17.9652 4.95118C17.9652 4.7754 17.9613 4.59572 17.9535 4.41993C18.7566 3.83918 19.4496 3.11985 20 2.29572C19.2521 2.62847 18.458 2.84579 17.6449 2.94025C18.5011 2.42706 19.1421 1.62086 19.4492 0.671106C18.6438 1.14843 17.763 1.48514 16.8445 1.66681C16.2257 1.00927 15.4075 0.573897 14.5164 0.42801C13.6253 0.282122 12.711 0.433842 11.9148 0.859714C11.1186 1.28559 10.4848 1.96189 10.1115 2.78406C9.73825 3.60623 9.64619 4.52848 9.84961 5.40822C8.21874 5.32638 6.62328 4.90272 5.16665 4.16472C3.71002 3.42671 2.42474 2.39083 1.39414 1.12423C0.870333 2.02734 0.710047 3.096 0.945859 4.11304C1.18167 5.13008 1.79589 6.01917 2.66367 6.59962C2.01219 6.57894 1.37498 6.40353 0.804688 6.0879V6.13868C0.804104 7.08643 1.13175 8.00512 1.73192 8.73861C2.3321 9.47209 3.16777 9.9751 4.09687 10.1621C3.49338 10.3272 2.85999 10.3513 2.2457 10.2324C2.50788 11.0475 3.01798 11.7604 3.70481 12.2716C4.39164 12.7828 5.22093 13.0668 6.07695 13.084C4.62369 14.2256 2.82848 14.8447 0.980469 14.8418C0.652739 14.8413 0.325333 14.8212 0 14.7817C1.87738 15.9861 4.06128 16.6258 6.2918 16.6246Z"
      fill="currentColor"
    />
  </SvgIcon>
);
