import { configureStore } from '@reduxjs/toolkit';
import { UserState } from 'entities/user/model';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { ignoredActions } from './ignoredSerializableActions';
import reducer from './rootReducer';

type MyPersistConfig<T> = PersistConfig<T> & { whitelist: (keyof T)[] };

const userPersistConfig: MyPersistConfig<UserState> = {
  key: 'user',
  storage,
  whitelist: ['provider', 'address', 'network', 'chainType'],
};

const reducers = {
  ...reducer,
  user: persistReducer(userPersistConfig, reducer.user),
};

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: { ignoredActions },
    }),
});

export type State = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
