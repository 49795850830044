import { userModel } from 'entities/user';
import { Chains, ContractsNames, ContractsService, createAppAsyncThunk, logger, RequestWithWeb3Provider } from 'shared';

import { actionTypes } from '../action-types';

export const getUserInfo = createAppAsyncThunk(
  actionTypes.GET_USER_INFO,
  async ({ web3Provider }: RequestWithWeb3Provider, { rejectWithValue, getState }) => {
    try {
      const contractService = new ContractsService(web3Provider);
      const { chainType, address } = userModel.selectors.selectUserWeb3Info(getState());

      const { address: usdtAddress } = ContractsService.getContractData(ContractsNames.USDT, Chains.BSC, chainType);
      const { address: usdcAddress } = ContractsService.getContractData(ContractsNames.USDC, Chains.BSC, chainType);
      const { address: cratAddress } = ContractsService.getContractData(ContractsNames.Crat, Chains.BSC, chainType);

      const crowdsaleContract = contractService.getCrowdsaleContract(chainType);
      const cratContract = contractService.getErc20Contract(cratAddress);
      const usdtContract = contractService.getErc20Contract(usdtAddress);
      const usdcContract = contractService.getErc20Contract(usdcAddress);

      if (address) {
        const [cratBalance, usdtBalance, usdcBalance] = await Promise.all([
          cratContract.methods.balanceOf(address).call(),
          usdtContract.methods.balanceOf(address).call(),
          usdcContract.methods.balanceOf(address).call(),
        ]);

        const { referralReceived, referrer, totalSpend } = await crowdsaleContract.methods.userInfos(address).call();

        return { cratBalance, usdtBalance, usdcBalance, referralReceived, totalSpend, referralFather: referrer };
      }

      return null;
    } catch (err) {
      logger('crowdsaleData', err);
      return rejectWithValue(err);
    }
  },
);
