export const UNITS = {
  PER_DAY: 1000 * 60 * 60 * 24,
  PER_HOUR: 1000 * 60 * 60,
  PER_MINUTE: 1000 * 60,
  PER_SECOND: 1000,
} as const;

export const CELL_LABELS: Record<keyof ReturnType<typeof getEndsIn>['parts'], string> = {
  days: 'days',
  hours: 'hours',
  minutes: 'min.',
  seconds: 'sec.',
};

const getUnit = (days: number, hours: number, minutes: number, seconds: number) => {
  if (days) {
    return [days, 'days'] as const;
  }

  if (hours) {
    return [hours, 'hours'] as const;
  }

  if (minutes) {
    return [minutes, 'minutes'] as const;
  }

  return [seconds, 'seconds'] as const;
};

export const getEndsIn = (diffInMs: number) => {
  const days = Math.floor(diffInMs / UNITS.PER_DAY);
  const hours = Math.floor((diffInMs % UNITS.PER_DAY) / UNITS.PER_HOUR);
  const minutes = Math.floor((diffInMs % UNITS.PER_HOUR) / UNITS.PER_MINUTE);
  const seconds = Math.floor((diffInMs % UNITS.PER_MINUTE) / UNITS.PER_SECOND);

  return {
    parts: { days, hours, minutes, seconds },
    unit: getUnit(days, hours, minutes, seconds),
  };
};

export const getUtcTimestamp = () => {
  const date = new Date();

  return Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds(),
  );
};
