import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const InfoCircle: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 20 20"
    sx={{
      width: '20px',
      height: '20px',
      ...sx,
    }}
    {...props}
  >
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 14L10 10M10 6L9.99 6M1 10C1 5.02944 5.02944 0.999999 10 0.999999C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
