// eslint-disable-next-line no-shadow
export enum RequestStatus {
  INIT = 'INIT',
  REQUEST = 'pending',
  SUCCESS = 'fulfilled',
  ERROR = 'rejected',
  RESET = 'RESET',
}

export type UIState = Record<string, RequestStatus>;

export type StateWithUIState<P = Record<string, unknown>> = {
  ui: UIState;
} & P;
