import { NavLink } from 'react-router-dom';
import { Container, Stack, Typography } from '@mui/material';
import { COLOR_ACCENT, COLOR_PRIMARY, TRANSITION_300 } from 'shared';
import { footerNavLinks, navLinks, socials } from 'widgets/layout/constants';

import { Community } from './ui';

const copyrightYear = new Date().getFullYear();

export const Footer = () => {
  return (
    <Container>
      <Community />
      <Stack
        component="footer"
        sx={{
          pb: 5,
        }}
      >
        <Stack direction={{ lg: 'row' }} alignItems={{ md: 'center' }} useFlexGap gap={3}>
          <Typography variant="subtitle1" fontWeight={400} whiteSpace="nowrap">
            Copyright © {copyrightYear} CratD2C
          </Typography>
          <Stack
            direction={{ lg: 'row' }}
            alignItems={{ md: 'center' }}
            justifyContent="space-between"
            spacing={{ xs: 4, lg: 12 }}
            width="100%"
          >
            <Stack
              component="nav"
              direction={{ md: 'row' }}
              alignItems={{ md: 'center' }}
              justifyContent="center"
              useFlexGap
              gap={{ xs: 2, lg: 4.5 }}
              flex={1}
            >
              {[...navLinks, ...footerNavLinks].map((link) => {
                return (
                  <Typography
                    key={link.path}
                    component={NavLink}
                    to={link.path}
                    target="_blank"
                    variant="subtitle1"
                    sx={{
                      transition: TRANSITION_300,
                      '&:hover, &.active': {
                        color: COLOR_ACCENT,
                      },
                    }}
                  >
                    {link.title}
                  </Typography>
                );
              })}
            </Stack>
            <Stack direction="row" alignItems="center" spacing={{ xs: 3.85, md: 2, lg: 3.85 }}>
              {socials.map(({ Icon, url }) => {
                return (
                  <a key={url} rel="noreferrer" href={url} target="_blank">
                    <Icon
                      sx={{
                        color: COLOR_PRIMARY,
                      }}
                    />
                  </a>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};
